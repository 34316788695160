import { createRouter, createWebHistory } from "vue-router";

const routes = [
  //Login
  {
    path: "/",
    name: "login1",
    component: () =>
      import(/* webpackChunkName: "login1" */ "../views/pages/login/Login.vue"),
  },
  {
    path: "/login",
    name: "login2",
    component: () =>
      import(/* webpackChunkName: "login2" */ "../views/pages/login/Login.vue"),
  },

  //Views Admin
  {
    path: "/admin",
    name: "admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "../views/layouts/AdminLayout.vue"
      ),
    children: [
      {
        path: "inicio",
        name: "home",
        component: () =>
          import(
            /* webpackChunkName: "home" */ "../views/pages/inicio/Inicio.vue"
          ),
      },
      {
        path: "panel",
        name: "panel",
        component: () =>
          import(
            /* webpackChunkName: "panel" */ "../views/pages/panel/Dashboard.vue"
          ),
      },
      {
        path: "movimientos",
        name: "movimientos",
        component: () =>
          import(
            /* webpackChunkName: "movimientos" */ "../views/pages/movimientos/Listado.vue"
          ),
      },
      {
        path: "movimientos/nuevo",
        name: "nuevoMovimientos",
        component: () =>
          import(
            /* webpackChunkName: "nuevoMovimientos" */ "../views/pages/movimientos/Formulario.vue"
          ),
      },
      {
        path: "viviendas",
        name: "viviendas",
        component: () =>
          import(
            /* webpackChunkName: "viviendas" */ "../views/pages/viviendas/Listado.vue"
          ),
      },
      {
        path: "usuarios",
        name: "usuarios",
        component: () =>
          import(
            /* webpackChunkName: "usuarios" */ "../views/pages/usuarios/Listado.vue"
          ),
      },
      {
        path: "blog",
        name: "blog",
        component: () =>
          import(/* webpackChunkName: "blog" */ "../views/pages/blog/Blog.vue"),
      },
      {
        path: "historico",
        name: "historicoCondominio",
        component: () =>
          import(
            /* webpackChunkName: "historicoCondominio" */ "../views/pages/historico_condominio/Historico.vue"
          ),
      },
      {
        path: "configuracion",
        name: "configuracion",
        component: () =>
          import(
            /* webpackChunkName: "configuracion" */ "../views/pages/configuracion/Configuraciones.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
